.work-template {
  margin-top: 450px;
  padding: 30px 0;
  margin: 0 3%;
  border-radius: 8px;
  background-color: var(--lightBlueColor);
  .work-inner {
    width: 320px;
    margin: 0 auto;

    h1.work_title {
      font-weight: 800;
      font-size: 48px;
      /* line-height: 1.14; */
      margin: 0;
      padding: 0;
    }

    p.work-description {
      color: var(--subFontColor);
      font-weight: 400;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 30px;

      a{
        text-decoration: underline;
        color: var(--accentColor);
      }
    }

    .work_skills {
      .tags {
        margin: 0 0 45px 0;
        padding: 0;

        li {
          font-size: 12px;
          color: var(--accentColor);
          list-style: none;
          display: inline-block;
          border: 2px solid var(--accentColor);
          border-radius: 4px;
          margin: 0 10px 10px 0;
          padding: 0.5em 1em;
        }
      }
    }

    h2.headline {
      font-size: 33px;
      line-height: 1.1;
      color: var(--fontColor);
      padding: 0;
      margin: 0 0 10px;
    }

    p.headline-content {
      font-size: 20px;
      line-height: 1.27;
      color: var(--subFontColor);
      padding: 0;
      margin: 0 0 30px;
    }

    ul.work_links {
      margin: 0;
      padding: 0;
      
      li {
        display: block;
        list-style-type: none;
      }

      li a {
        display: block;
        height: 30px;
        text-decoration: none;
        font-size: 20px;
        color: var(--accentColor);
        line-height: 1.14;
        margin-bottom: 5px;
        // background-color: aquamarine;
      }

      li a:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .content-images{
      width: 80%;
      margin: 0 auto;

      img.content-img {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 60px;
      }
    }
    
  }
}

@media screen and (min-width: 480px) {
  .work-template {
    padding: 100px 0;
    .work-inner {
      width: 400px;
      h1.work_title {
        font-size: 44px;
      }
      p.work-description {
        font-size: 18px;
      }

      h2.headline {
        font-size: 33px;
      }

      p.headline-content {
        font-size: 18px;
      }

      ul.work_links {
        li a {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .work-template {
    padding: 100px 0;
    .work-inner {
      width: 70%;
      margin-left: 20%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .work-template {
    padding: 100px 0;
    .work-inner {
      width: 56%;
      margin-left: 24%;
      h1.work_title {
        font-size: 48px;
      }
      p.work-description {
        font-size: 20px;
      }

      h2.headline {
        font-size: 33px;
      }

      p.headline-content {
        font-size: 20px;
      }

      ul.work_links {
        li a {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
}
