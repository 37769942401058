.contents_wrapper {
  max-width: 100%;
  padding: 30px 0;
  margin: 0 3%;
  border-radius: 8px;
  background-color: var(--lightBlueColor);
}

.contents-inner {
  width: 90%;
  margin: auto;
}

.view_more {
  text-align: right;
}

.view_more a {
  color: var(--subFontColor);
}

.view_more a:hover {
  color: var(--accentColor);
}

@media screen and (min-width: 480px) {
  .contents-inner {
    width: 400px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents_wrapper {
    padding: 60px 0 120px;
    margin: 0 3%;
    border-radius: 8px;
    background-color: var(--lightBlueColor);
  }

  .contents-inner {
    width: 70%;
    margin-left: 20%;
  }
}

@media screen and (min-width: 1024px) {
  .contents_wrapper {
    padding: 60px 0 120px;
    margin: 0 5%;
    border-radius: 8px;
    background-color: var(--lightBlueColor);
  }

  .contents-inner {
    width: 700px;
    margin-left: 23%;
  }
}

@media screen and (min-width: 1440px) {
  .contents-inner {
    width: 800px;
    /* margin-left: 26%; */
    margin: 0 auto;
  }
}
