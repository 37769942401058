.blog {
  margin-top: 450px;
  padding: 30px 0;
  margin: 0 3%;
  border-radius: 8px;
  background-color: var(--lightBlueColor);
  .blog-wrapper {
    width: 90%;
    margin: auto;

    .blog-inner {
      width: 100%;
      margin-bottom: 60px;

      .blog-image {
        flex: 1;
        a {
          img {
            display: block;
            border-radius: 8px;
            width: 100%;
            margin: 0 auto 30px;
          }
        }
      }
      .blog-description {
        flex: 1;
        h3 {
          font-size: 33px;
          line-height: 1.14;
          padding: 0;
          margin: 0;
        }
        a {
          text-decoration: none;
          color: var(--fontColor);
        }

        a:hover {
          text-decoration: underline;
        }

        p {
          font-size: 18px;
          line-height: 1.27;
          color: var(--accentColor);
        }
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .blog {
    .blog-wrapper {
      width: 400px;
      margin: auto;

      .blog-inner {
        .blog-image {
          flex: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .blog {
    padding: 100px 0;
    margin: 0 3%;
    .blog-wrapper {
      width: 70%;
      margin-left: 20%;

      .blog-inner {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5%;
        padding: 0;
        .blog-image {
          a {
            img {
              width: 250px;
              margin: 0;
            }
          }
        }

        .blog-description {
          h3 {
            font-size: 30px;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .blog {
    padding: 100px 0;
    margin: 0 5%;
    .blog-wrapper {
      width: 700px;
      margin-left: 26%;

      .blog-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5%;
        padding: 0;
        .blog-image {
          a {
            img {
              width: 280px;
              margin: 0;
            }
          }
        }

        .blog-description {
          h3 {
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .blog {
    .blog-wrapper {
      width: 800px;
      margin: 0 auto;
      .blog-inner {
        .blog-image {
          a {
            img {
              width: 350px;
            }
          }
        }

        .blog-description {
          h3 {
            font-size: 33px;
          }
        }
      }
    }
  }
}
