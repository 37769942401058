@import-normalize; /* bring in normalize.css styles */

:root {
  --baseColor: rgb(119, 172, 185);
  --fontColor: #21242c;
  --subFontColor: #6e7075;
  --accentColor: #ef6130;
  --subAccentColor: #f37d56;
  --mediumOrange: #f56b3d;
  --greyColor: #c1c2c6;
  /* --lightBlueColor: #63bfe8; */
  --lightBlueColor: #ecf4f8;
  --mainFont: 'PT Sans', sans-serif;
  --subFont: 'PT Mono', monospace;
}
body {
  margin: 0;
  padding: 0;
  font-family: var(--mainFont);
  /* font-family: "PT Mono", monospace;
  font-family: "PT Sans", sans-serif;
  font-family: "Open Sans", sans-serif; */
  font-size: 16px;
  box-sizing: border-box;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.clearfix:before {
  content: '';
  display: block;
  clear: both;
}

.clearfix {
  display: block;
}
