.about {
  margin-top: 450px;
  padding: 30px 0;
  margin: 0 3%;
  border-radius: 8px;
  background-color: var(--lightBlueColor);

  .about-inner {
    width: 90%;
    margin: 0 auto;

    .about-introduction {
      margin-bottom: 40px;
      h1 {
        font-weight: 400;
        font-size: 48px;
        line-height: 1.3;
        margin: 0;
        padding: 0;
      }

      ul#sns-links {
        list-style: none;
        font-family: var(--subFont);
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        a {
          display: block;
          text-decoration: none;
          color: var(--accentColor);
          font-size: 17px;
          line-height: 1.14;
          margin-right: 17px;
          margin-bottom: 8px;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.topic {
  margin-bottom: 40px;
  & h2 {
    font-size: 33px;
    line-height: 1.14;
    margin: 0;
    padding: 0;
  }
  & p {
    font-size: 18px;
    line-height: 1.28;
    color: var(--subFontColor);
  }
}

@media screen and (min-width: 480px) {
  .about {
    .about-inner {
      width: 400px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about {
    padding: 100px 0;
    margin: 0 3%;
    .about-inner {
      width: 70%;
      margin-left: 20%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .about {
    padding: 100px 0;
    margin: 0 5%;
    .about-inner {
      width: 700px;
      margin-left: 26%;
      .about-introduction {
        display: flex;

        ul#sns-links {
          list-style: none;
          font-family: var(--subFont);
          text-align: right;
          display: block;

          a {
            display: block;
            text-decoration: none;
            color: var(--accentColor);
            font-size: 17px;
            line-height: 1.14;
            margin-bottom: 8px;
          }

          a:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .about {
    .about-inner {
      width: 800px;
      margin: 0 auto;
    }
  }
}
