header {
  max-width: 100%;
  margin-left: 0%;
  height: auto;
  position: relative;
  display: block;
}

header.about-header,
header.work-header,
header.work-ph-header {
  max-width: 100%;
  height: auto;
  padding-top: 60px;
  img#about_photo,
  img#work-photo,
  img#work-ph-photo {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    top: 81px;
    box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.1);
  }
}

header.work-ph-header a#link_msgif {
  display: block;
  position: relative;
}

header.work-ph-header a#productHunt_msgif {
  display: block;
  position: absolute;
  top: 2%;
  left: 1%;

  img {
    width: 140px;
  }
}

.main-img {
  img#profile-photo {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    position: static;
    border-radius: 8px;
  }

  img#speech-bubble {
    width: 258px;
    height: 152px;
    position: absolute;
    top: 8%;
    right: -9%;
    z-index: 3;
    transform: scale(0.65);
  }
}

.introduction {
  width: 90%;
  margin: 0 auto;
  margin-top: 40px;

  .introduction-inner {
    h1 {
      font-size: 44px;
      font-family: var(--mainFont);
      font-weight: 400;
      line-height: 1.14;
      color: var(--subFontColor);
      padding: 0;
      margin: 0;

      span#name {
        font-weight: 700;
        color: var(--fontColor);
      }

      span#job-title {
        color: var(--accentColor);
      }
    }

    p {
      min-width: 300px;
      font-size: 14px;
      color: var(--subFontColor);
      line-height: 1.4;
      letter-spacing: 1px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        margin: 0;
        padding: 0;
        a {
          font-size: 24px;
          color: var(--subFontColor);
          text-decoration: none;
          display: block;
          padding: 18px 18px 18px 0;
          // margin: 5px;
          // background-color: aqua;
        }
        a:hover {
          color: var(--accentColor);
          cursor: pointer;
          text-decoration: underline;
        }
        .fa-envelope {
          padding-top: 6px;
        }
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .main-img {
    img#profile-photo {
      // height: 380px;
      margin: 0 auto;
      position: static;
      z-index: 1;
    }

    img#speech-bubble {
      transform: scale(0.85);
      top: 12%;
      right: 2%;
      z-index: 3;
    }
  }

  .introduction {
    width: 320px;
    position: static;
  }

  header.about-header,
  header.work-header {
    max-width: 100%;
    height: 300px;
    margin: 0 0 60px;
    padding-top: 60px;
    img#about_photo,
    img#work-photo,
    img#work-ph-photo {
      display: block;
      max-width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  header.work-header {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header {
    width: 70%;
    height: 450px;
    margin-left: 20%;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .main-img {
    flex-shrink: 0;
    img#profile-photo {
      display: block;
      height: 400px;
      margin: 60px 0 0 0;
      z-index: 1;

      // margin: 0;
      // position: absolute;
      // top: 5%;
      // right: 0;
      // z-index: 1;
    }
    img#speech-bubble {
      position: absolute;
      top: 5%;
      right: -8%;
      z-index: 3;
      transform: scale(0.85);
    }
  }

  .introduction {
    width: 380px;
    padding: 0px;
    background-color: transparent;
    // position: absolute;
    // left: 0;
    .introduction-inner {
      margin-top: 60px;
      h1 {
        font-size: 2.5rem;
        font-family: var(--mainFont);
        font-weight: 400;
        line-height: 1.14;
        color: var(--subFontColor);

        span#name {
          font-weight: 700;
          color: var(--fontColor);
        }

        span#job-title {
          color: var(--accentColor);
        }
      }
    }
  }

  header.about-header,
  header.work-header {
    width: 70%;
    height: 450px;
    margin-left: 20%;
    margin-bottom: 0;
    padding-top: 0;
    position: relative;
    img#about_photo,
    img#work-photo,
    img#work-photo {
      position: absolute;
      height: 350px;
      top: 28%;
    }
  }

  header.work-ph-header a#productHunt_msgif {
    img {
      width: 200px;
    }
  }
}

@media screen and (min-width: 1024px) {
  header {
    width: 700px;
    height: 450px;
    margin-left: 26%;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .main-img {
    flex-shrink: 0;
    img#profile-photo {
      display: block;
      height: 450px;
      margin: 30px 0 0 0;
      // position: absolute;
      // top: 5%;
      // right: 0;
      // z-index: 1;
    }
    img#speech-bubble {
      position: absolute;
      top: 7%;
      right: -7%;
      z-index: 3;
      transform: scale(0.9);
    }
  }

  .introduction {
    // width: 450px;
    // padding: 0px;
    // background-color: transparent;
    // position: absolute;
    // top: 38%;
    // left: 4%;
    // z-index: 2;
    margin: 40px 0 0;
    position: absolute;
    left: 0;

    .introduction-inner {
      margin-top: 90px;
      h1 {
        width: 500px;
        font-size: 3rem;
      }
    }
  }

  header.about-header,
  header.work-header {
    width: 700px;
    height: 450px;
    margin-left: 26%;
    margin-bottom: 0;
    padding-top: 0;
    position: relative;
    img#about_photo,
    img#work-photo,
    img#work-photo {
      position: absolute;
      height: 400px;
      top: 18%;
      left: 5%;
    }
  }

  header.work-ph-header a#productHunt_msgif {
    img {
      width: 200px;
    }
  }
}

@media screen and (min-width: 1440px) {
  header {
    width: 800px;
    margin: 0 auto;
  }

  .main-img {
    flex-shrink: 0;
    img {
      height: 800px;
      margin: 0;
      position: absolute;
      top: 5%;
      right: 0;
    }
  }
  header.about-header,
  header.work-header {
    width: 800px;
    margin: 0 auto;

    img#about_photo,
    img#work-photo,
    img#work-photo {
      height: 400px;
      top: 18%;
    }
  }
}
